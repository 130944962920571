import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "./Layout.css";

function Layout({children}) {
    return (
        <>
            <Header />
            <div className="content">
                { children }
            </div>
            <Footer />
        </>
    )
}

export default Layout;