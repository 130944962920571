import React from "react";
import { useState, useEffect } from "react";
import { Navbar, Nav } from "rsuite";
import HomeIcon from "@rsuite/icons/legacy/Home";
import Logo from "../../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header({ ...props }) {
  const [activeKey, setActiveKey] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!activeKey) return;
    changeRoute(activeKey);
  }, [activeKey]);

  const changeRoute = (url) => navigate(url);

  return (
    <Navbar {...props} className="navbar">
      <Navbar.Brand href="/">
        <img alt="logo" src={Logo} width="100px" />
      </Navbar.Brand>
      <Nav onSelect={setActiveKey} activeKey={activeKey} className="nav">
        <Nav.Item eventKey="/" icon={<HomeIcon />}>
          Home
        </Nav.Item>
        <Nav.Item eventKey="/about">About</Nav.Item>
        <Nav.Item eventKey="/products">Products</Nav.Item>
        <Nav.Item eventKey="/contact">Contact</Nav.Item>
      </Nav>
    </Navbar>
  );
}

export default Header;
