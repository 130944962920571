import React from "react";
import { Content } from "rsuite";
import LinkedInLogo from "../../assets/linkedin.png";
import EmailLogo from "../../assets/email.png";
import "./ContactUs.css";

function ContactUs(props) {
  const { displayText } = props;

  return (
    <div className="contact-us-container">
      <div className="contact-us text-with-background" style={(displayText)? {"height": "140px"} : { height: "110px"}}>
        <Content>
          { displayText &&
            <div>Contact Us!</div>
          }
          <span className="image">
            <a href="https://www.linkedin.com/company/apolomedia-com" target="_blank" rel="noopener noreferrer">
              <img alt="linkedIn-logo" className="contact-logo" src={LinkedInLogo} />
            </a>
          </span>
          <span className="image">
            <a href="mailto:contacto@apolomedia.com" className="a-footer" rel="noopener noreferrer">
              <img alt="mail-logo" className="contact-logo" src={EmailLogo} />
            </a>
          </span>
        </Content>
      </div>
    </div>
  );
}

export default ContactUs;
