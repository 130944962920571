import React from "react";
import LinkedInLogo from "../../../assets/linkedin.png";
import "./Footer.css";

function Footer() {
  const getYear = () => new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="social-networks-logos scale">
        <a href="https://www.linkedin.com/company/apolomedia-com" target="_blank" rel="noopener noreferrer">
            <img alt="linkedIn-logo" src={LinkedInLogo} width="20px" />
        </a>
      </div>

      <div className="mail">
        <a href="mailto:contacto@apolomedia.com" className="a-footer" rel="noopener noreferrer">
          <b>Contact us!</b>
        </a>
      </div>

      <div className="license">
        <span>ApoloMedia - {getYear()}</span>
      </div>
    </div>
  );
}

export default Footer;
