import React from "react";
import { Container, Content } from "rsuite";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./Products.css";

function Products() {
  return (
    <div className="products-container">
      <Container>
        <Content>
          <div className="intro-container">
            <div className="text-with-background">
              <h1> Products </h1>
            </div>

            <div className="text-with-background">
              <div className="products-us-text">
                <p>
                  To help you launch and grow a successful Video On Demand (VOD)
                  business, we offer a comprehensive suite of tools and
                  services. Our media manager platform is designed to make it
                  easy for you to upload and manage your media. With our
                  platform, you can upload your videos and transcode them on the
                  fly. <br /><br />
                  This means that your videos will be automatically converted
                  into the right format and quality for any device, ensuring
                  that your content can be enjoyed by as many people as
                  possible.
                </p>

                <p>
                  In addition to our media manager platform, we also provide you
                  with powerful editing tools that allow you to create and
                  customize your own player with your brand colors and logo.
                  With our editing tools, you can add custom branding and design
                  elements to your player, making it a seamless part of your
                  brand.<br /><br />
                </p>

                <p>
                  Our media manager platform is also equipped with a variety of
                  management tools that make it easy to manage your media,
                  players, and users. With our platform, you can easily create
                  and manage playlists, organize your media into categories, and
                  track your viewership and engagement metrics. <br /><br />
                </p>

                <p>
                  To further streamline your workflow and enable custom
                  integrations, we also provide you with an API. With our API,
                  you can upload videos, list categories, list last content and
                  more, enabling you to integrate our platform with your
                  existing workflows and systems. <br /><br />
                  With our suite of tools and services, you'll have everything
                  you need to launch and grow a successful VOD business.
                </p>
              </div>
            </div>

            <ContactUs displayText={true} />
          </div>
        </Content>
      </Container>
    </div>
  );
}

export default Products;
