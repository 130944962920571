import React from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./Home.css";

function Home() {
  return (
    <div className="home-container">
      <div className="intro-container">
        <div className="text-with-background">
          <h1> Looking for cloud streaming media solution? </h1>
        </div>

        <div className="text-with-background">
          <h2>
            We provide all in one cloud solution service for media streaming to
            help your business grown fast
          </h2>
        </div>

        <div className="decorated-iframe">
          <iframe
            title="Video VOD - Demo"
            src="https://api.apolomedia.com/player/embed/playerId/OSWFFH1531187504/contentId/121"
            frameBorder="0"
            width="100%"
            height="100%"
            allowFullScreen={true}
          ></iframe>

          <ContactUs displayText={true} />
        </div>
      </div>
    </div>
  );
}

export default Home;
