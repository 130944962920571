import React from "react";
import { Container, Content } from "rsuite";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact-container">
      <Container>
        <Content>
          <div className="intro-container">
            <div className="text-with-background">
              <h1> Contact </h1>
            </div>

            <div className="text-with-background">
              <div className="contact-us-text">
                <div class="contact">
                  <p>Feel free to reach out to us:</p>
                  <ul>
                    <li>
                      Email: &nbsp;
                      <a href="mailto:contacto@apolomedia.com" className="a-footer" rel="noopener noreferrer">
                        Send us an e-mail clicking here
                      </a>
                    </li>
                    <li>
                      LinkedIn: &nbsp;
                      <a href="https://www.linkedin.com/company/apolomedia-com" target="_blank" rel="noopener noreferrer">
                        ApoloMedia.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ContactUs displayText={false} />
          </div>
        </Content>
      </Container>
    </div>
  );
}

export default Contact;
