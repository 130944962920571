import React from "react";
import { Container, Content } from "rsuite";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <Container>
        <Content>
          <div className="intro-container">
            <div className="text-with-background">
              <h1> About ApoloMedia </h1>
            </div>

            <div className="text-with-background">
              <div className="about-us-text">
                <p>
                  Looking for a reliable and affordable media streaming solution? <br />Look no further than ApoloMedia!<br /><br />
                  Our startup is dedicated to providing cutting-edge technology to help businesses of all sizes succeed in the world of online video.<br />
                  Whether you're looking to stream live events, share
                  educational content, or deliver on-demand movies and TV shows,
                  we've got you covered.<br /><br />
                </p>
                <p>
                  We understand that the world of media streaming can be
                  overwhelming, with all sorts of technical jargon and complex
                  systems to navigate. That's why we've made it our mission to
                  simplify the process, providing an easy-to-use platform that
                  takes care of all the heavy lifting for you. From transcoding
                  to content delivery, we've got everything you need to get your
                  content in front of your audience with minimal fuss.<br /><br />
                </p>
                <p>
                  But don't just take our word for it – our satisfied customers
                  are a testament to the quality of our service. With
                  ApoloMedia, you can rest assured that you're getting the best
                  in the business, with a team of dedicated professionals who
                  are always on hand to help you with any questions or concerns
                  you may have.<br /><br />
                </p>
                <p>
                  So why wait? If you're looking for a reliable and affordable
                  media streaming solution, look no further than ApoloMedia.
                  Contact us today and start streaming like a pro!
                </p>
              </div>
            </div>

            <ContactUs displayText={true} />
            
          </div>
        </Content>
      </Container>
    </div>
  );
}

export default About;
